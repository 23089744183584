import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { delay } from 'rxjs/operators';
import { LoaderService } from './shared/interceptor/loader.service';
declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  isLoaded: boolean = false;
  route;
  // request: HttpRequest<any>;
  constructor(private _loading: LoaderService, private http: HttpClient, private router: Router){
    this.route = this.router;
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
    // console.log('request', this.request)
  }

  ngOnInit(){
    this.listenToLoading();
        // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    $(document).ready(()=> {
      $("body").tooltip({ selector: '[data-bs-toggle=tooltip]'});
    });
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoaded = loading;
      });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isLoaded = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoaded = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoaded = false;
    }
    if (event instanceof NavigationError) {
      this.isLoaded = false;
    }
  }
}
