import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import { I } from '@angular/cdk/keycodes';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let uid = localStorage.getItem('uid');
        if (uid) {
            return true
        }
        else {
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        }



        // if (localStorage.getItem('uid')) {
        //     const clpUser = this.authenticationService.currentUser();


        //     if (clpUser) {
        //         // logged in so return true
        //         return true;

        //     }
        // } 
        // else {
        //     const clpUser = this.authFackservice.currentUserValue;
        //     if (clpUser) {
        //         // logged in so return true
        //         return true;

        //     }
        // }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        // return false;
    }
}
