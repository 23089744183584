import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = environment.baseUrl;
  token = 'Jamesbond_007';
  constructor(private socket: Socket, private http: HttpClient) { }
  public socketConnection() {
    this.socket.emit('bigboyget', {
      token: this.token,      
    });
    console.log(this.token,'connection');

  }

  public getSocketMessages = ()=>{
    return Observable.create((observer)=>{
      this.socket.on(this.token, (message)=>{
        observer.next(message);
        observer.complete();

      });
    });
  }  
  public getSocketChatMessages = ()=>{
    return Observable.create((observer)=>{
      this.socket.on(this.token, (message)=>{
        observer.next(message);
      });
    });
  }
  public removeSocketConnection=()=>{
    return this.socket.removeAllListeners()
  }
  sendMessage(msg:any){
    return this.http.post(`${this.baseUrl}/api/sellers/setChat`, msg);
  }
  getMessages(chat:any){
    return this.http.get(`${this.baseUrl}/api/sellers/getChat?receiver_id=${chat.receiver_id}&sender_id=${chat.sender_id}&content_id=${chat.content_id}`)
  }

}
