import { Component, OnInit } from '@angular/core';
import { Router, } from '@angular/router';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  // baseUrl = environment.mainUrl;
  user;
  uid: number;
  constructor(private router: Router, private http: HttpClient, private viewportScroller: ViewportScroller) { }
  extra = true;
  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('clpUser'));
    if (localStorage.getItem("clpUser") != null) {
      this.extra = false;

    }



  }
  logout() {

    this.router.navigate(['/account/login']);
    localStorage.clear();
  }
  dashboard() {
    this.user = JSON.parse(localStorage.getItem('clpUser')).type
    if (this.user == 1) {
      this.router.navigate(['/buyer/marketplace']);
    } else {
      this.router.navigate(['/seller/contentlist']);
    }

  }
  gotoScroll(itemId:string){
    this.viewportScroller.scrollToAnchor(itemId);
  }
}
