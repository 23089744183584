<div *ngIf="isLoaded">
    <div class="loading">
        <div class="loader"></div>

      </div>
</div>





<router-outlet></router-outlet>
