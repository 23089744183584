import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { AuthenticationService } from "../../../core/services/auth.service";
import { environment } from "../../../../environments/environment";
import { FetchDataService } from "src/app/core/services/fetch-data.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
    resetForm: FormGroup;
    submitted = false;
    error = "";
    success = "";
    loading = false;
    j;

    // set the currenr year
    year: number = new Date().getFullYear();

    // tslint:disable-next-line: max-line-length
    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private fd: FetchDataService) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe((params: Params) => {
            this.j = params.params.j;
        });

        this.resetForm = this.formBuilder.group(
            {
                password: ["", [Validators.required, Validators.minLength(4)]],
                rpassword: ["", Validators.required]
            },
            { validator: this.passwordConfirming }
        );
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get("password").value !== c.get("rpassword").value) {
            return { invalid: true };
        }
    }

    ngAfterViewInit() {}

    // convenience getter for easy access to form fields
    get f() {
        return this.resetForm.controls;
    }

    /**
     * On submit form
     */
    onSubmit() {
        this.success = "";
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetForm.valid) {
            const formData = new FormData();
            formData.append("j", this.j);
            formData.append("password", this.f.password.value);
            formData.append("rpassword", this.f.rpassword.value);
            this.fd.resetPassword(formData).subscribe((res) => {
                if (res.code == 1) {
                    this.success = "Password Changed Successfully";
                    setTimeout(() => {
                        this.router.navigate(["/account/login"]);
                    }, 2000);
                } else {
                    // this.error = res.result;
                    this.error = "Something Went Wrong";
                }
            });
        }
    }
}
