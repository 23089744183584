<div class="account-pages my-5 pt-sm-5">
    <video muted="true" autoplay="autoplay" playsinline loop id="myVideo" oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source  src="assets/images/loginvideo.mp4" type="video/mp4">
        </video>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card_2 overflow-hidden">
                    <div class="bg-soft bg-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 id="reg"class="text-primary"> Reset Password</h5>
                                    <p id="para">Re-Password with Connekkt Media.</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <a routerLink="/">
                                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/connekkt-web-logo-black.png" alt="" class="rounded-circle" height="16">
                  </span>
                                </div>
                            </a>
                        </div>

                        <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>

                            <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                            <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert>

                            <div class="form-group">
                                <label class="control-label">Enter Password</label>
                                <input maxlength="100" type="password" formControlName="password" required="required" class="form-control" placeholder="Enter Password"
                                       [ngClass]="{ 'is-invalid': submitted && f.rpassword.errors }"/>
                                <div class="invalidError" *ngIf="(resetForm.get('password').invalid && submitted)">Password is required*</div>
                            </div>
                            <div class="form-group">
                                <label class="control-label">Re-Enter Password</label>
                                <input maxlength="100" type="password" formControlName="rpassword" required="required" class="form-control" placeholder="Re-Enter Password"
                                       [ngClass]="{ 'is-invalid': submitted && f.rpassword.errors }"/>
                                <div *ngIf="(resetForm.get('rpassword').invalid && submitted)" class="invalidError">Retype Password is required*</div>
                                <div *ngIf="resetForm.get('rpassword').dirty" class="invalidError">
                                    <span *ngIf="resetForm.errors?.invalid"> Password doesn't match* </span>
                                </div>
                            </div>


                            <div class="text-end">
                                <button class="btn btn-primary w-md" type="submit">Change Password</button>
                            </div>
                        </form>

                    </div> <!-- end card-body-->
                </div>
                <!-- end card -->
                <div class="mt-5 text-center">
                    <p>Remember It ? <a routerLink="/account/login" class="fw-medium text-primary"> Sign In here</a> </p>
                    <p>© {{year}} Content Market Place. Crafted with <i class="mdi mdi-heart text-danger"></i> by Connekkt Media</p>
                </div>

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->
