import { MenuItem } from "./menu.model";

export const BUYER_MENU: MenuItem[] = [
    {
        id: 1,
        label: "Order History",
        icon: "bx-home-circle",
        link: "/buyer/landing"
    },
    {
        id: 2,
        label: "MENUITEMS.CONTENT.LIST.MARKETPLACE",
        icon: "bx-shopping-bag",
        link: "/buyer/marketplace"
    },
    // {
    //     id: 3,
    //     label: "Chat Logs",
    //     icon: "bx-collection",
    //     link: "/buyer/chatlogs"
    // },
    {
        id: 4,
        label: "CHAT",
        icon: "bx-chat",
        link: "/buyer/chat"
    },
   
    {
        id: 5,
        label: "Offer Page",
        icon: "bxs-offer",
        link: "/buyer/offer-page",
        
    },
    {
        id: 6,
        label: "Sales Support",
        icon: "bx-support",
        link: "/buyer/faq"
    },
    // {
    //     id: 5,
    //     label: "Requirement Page",
    //     icon: "bx-customize",
    //     link: "/buyer/requirement-page"
    // }
];
