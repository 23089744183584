import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private router: Router,private ad: ActivatedRoute) { }
  message :any
  ngOnInit(): void {
    // this.ad.queryParamMap.subscribe((params: Params) => {
    //   console.log(params)
    // })
    if (this.router.url == '/error/2') {
        this.message = 'The link has expired. Please try the signup process again.';

        // setTimeout(() => {
        //   this.router.navigate(['/account/register-email'])
        // }, 2000);
    } else {
        this.message = 'The link has expired. Please try the signup process again.';
        // setTimeout(() => {
        //   this.router.navigate(['/account/register-email'])
        // }, 2000);
    }

  }

}
