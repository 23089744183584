<div data-spy="scroll" data-target=".fixed-top">
  <div>
      <!-- Navigation -->
      <nav class="navbar navbar-expand-lg fixed-top navbar-light">
        <div class="container">

            <!-- Text Logo - Use this if you don't have a graphic logo -->
            <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Kora</a> -->

            <!-- Image Logo -->
            <a class="navbar-brand logo-image" href="">
                <img src="assets/images/xchange.png" alt="alternative"></a>

            <!-- <button class="navbar-toggler p-0 border-0" type="button" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="true" aria-label="toggle navigation" data-toggle="collapse">
                <span class="navbar-toggler-icon"></span>
            </button> -->

            <div class="collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav ml-auto">
                    <!-- <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('header')">Home <span class="sr-only">(current)</span></a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('intro')">Intro</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('features')">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('details')">Details</a>
                    </li> -->
                    <li class="nav-item">
                        <a routerLink="/sale-support" class="nav-link page-scroll"  href="javascript.void(0)" *ngIf="!user"   >Sales Support </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/faqs" class="nav-link page-scroll"  href="javascript.void(0)" *ngIf="!user"   >FAQ's</a>
                    </li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Drop</a>
                        <div class="dropdown-menu" aria-labelledby="dropdown01">
                            <a class="dropdown-item page-scroll" href="article.html">Article Details</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item page-scroll" href="terms.html">Terms Conditions</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item page-scroll" href="privacy.html">Privacy Policy</a>
                        </div>
                    </li> -->
                     <li class="nav-item" *ngIf="this.extra" >
                            <a routerLink="/account/register-email" class="btn-solid-sm page-scroll" href="">Signup</a>
                            <a  routerLink="/account/login"  class="btn-solid-sm page-scroll" href="">Login</a>
                     </li>
                     <li class="nav-item" *ngIf="!this.extra"  >
                        <a  routerLink=""   class="btn-solid-sm page-scroll" (click)="dashboard()">Go to Content List</a>
                        <a routerLink="/account/login"   class="btn-solid-sm page-scroll" (click)="logout()">Logout</a>
                     </li>
                </ul>
               
                <!-- <span class="nav-item" *ngIf="user">
                    <a routerLink="/{{ (user.type == 1) ? 'buyer' : 'seller'}}" class="btn-solid-sm page-scroll" href="">Dashboard</a>
                </span> -->
            </div> <!-- end of navbar-collapse -->
        </div> <!-- end of container -->
    </nav> <!-- end of navbar -->
    <!-- end of navigation -->
  </div>

   
</div>
  
      <div class="container">

        <!-- For demo purpose -->
        <div class="row py-5 mt-5">
          <div class="col-lg-9 mx-auto text-white text-center">
            <h1 class="display-4">Frequently Asked Questions</h1>
            <p class="lead mb-0">If you're new to genie or looking to suprechange your current stack.</p>
          </div>
        </div><!-- End -->
      
        <div class="row">
          <div class="col-lg-9 mx-auto">
            <!-- Accordion -->
            <div id="accordionExample" class="accordion shadow">
      
              <!-- Accordion item 1 -->
              <div class="card">
                <div id="headingOne" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne"
                      class="btn btn-link text-dark font-weight-bold text-uppercase collapsible-link">What is Connekkt.media?</button>
                  </h2>
                </div>
                <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample" class="collapse show">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">Connekkt.media is a digital media agency specializing in content creation,
                       social media management, and digital marketing strategies. We help brands connect with their audience effectively.</p>
                  </div>
                </div>
              </div><!-- End -->
      
              <!-- Accordion item 2 -->
              <div class="card">
                <div id="headingTwo" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                      aria-controls="collapseTwo"
                      class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link">What services do you offer?</button>
                  </h2>
                </div>
                <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample" class="collapse">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">We Offer a Range of Services, Including: <br>

                      •        Content Creation: Blogs, videos, graphics <br>
                      •        Connekkt Xchange: Content marketplace <br>
                      •        Brand Development <br>
                      •        Analytics and Reporting</p>
                  </div>
                </div>
              </div><!-- End -->
      
              <!-- Accordion item 3 -->
              <div class="card">
                <div id="headingThree" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                      aria-controls="collapseThree"
                      class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link">How can I get started with Connekkt.media?</button>
                  </h2>
                </div>
                <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample" class="collapse">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">Getting Started is Easy! <br>
                      Simply register an account as a Seller or a Buyer based on your requirements and get started. If you have any queries, reach out to us at support@connekkt.media.</p>
                  </div>
                </div>
              </div><!-- End -->




                <!-- Accordion item 4 -->
                <div class="card">
                  <div id="headingfour" class="card-header bg-white shadow-sm border-0">
                    <h2 class="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false"
                        aria-controls="collapsefour"
                        class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link"> What industries do you work with?</button>
                    </h2>
                  </div>
                  <div id="collapsefour" aria-labelledby="headingfour" data-parent="#accordionExample" class="collapse">
                    <div class="card_2 p-3">
                      <p class="font-weight-light m-0">We currently work exclusively with the Entertainment Industry.</p>
                    </div>
                  </div>
                </div><!-- End -->



              <!-- Accordion item 5 -->
              <div class="card">
                <div id="headingfive" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false"
                      aria-controls="collapsefive"
                      class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link"> Is there a video to understand about Connekkt Media?</button>
                  </h2>
                </div>
                <div id="collapsefive" aria-labelledby="headingfive" data-parent="#accordionExample" class="collapse">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">Yes, please check our explainer video for more information.</p>
                  </div>
                </div>
              </div><!-- End -->



              <!-- Accordion item 6 -->
              <div class="card">
                <div id="headingsix" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false"
                      aria-controls="collapsesix"
                      class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link"> What is your pricing structure?</button>
                  </h2>
                </div>
                <div id="collapsesix" aria-labelledby="headingsix" data-parent="#accordionExample" class="collapse">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">For pricing-related queries, please reach out to infoconnekkt.media.</p>
                  </div>
                </div>
              </div><!-- End -->



               <!-- Accordion item 7 -->
               <div class="card">
                <div id="headingseven" class="card-header bg-white shadow-sm border-0">
                  <h2 class="mb-0">
                    <button type="button" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false"
                      aria-controls="collapseseven"
                      class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link">  How do I contact support?</button>
                  </h2>
                </div>
                <div id="collapseseven" aria-labelledby="headingseven" data-parent="#accordionExample" class="collapse">
                  <div class="card_2 p-3">
                    <p class="font-weight-light m-0">You can reach our support team via the contact form on our website or email us directly at support@connekkt.media. We’re here to help!</p>
                  </div>
                </div>
              </div><!-- End -->

                 <!-- Accordion item 8 -->
                 <div class="card">
                  <div id="headingeight" class="card-header bg-white shadow-sm border-0">
                    <h2 class="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapseeight" aria-expanded="false"
                        aria-controls="collapseeight"
                        class="btn btn-link collapsed text-dark font-weight-bold text-uppercase collapsible-link">  Who is a seller in Connekkt Media</button>
                    </h2>
                  </div>
                  <div id="collapseeight" aria-labelledby="headingeight" data-parent="#accordionExample" class="collapse">
                    <div class="card_2 p-3">
                      <p class="font-weight-light m-0">A seller in Connekkt Media typically refers to a content creator, brand, or individual offering content on the Connekkt Xchange Platform.</p>
                    </div>
                  </div>
                </div><!-- End -->


                 <!-- Accordion item 9 -->
                 <div class="card">
                  <div id="headingnine" class="card-header bg-white shadow-sm border-0">
                    <h2 class="mb-0">
                      <button type="button" data-toggle="collapse" data-target="#collapsenine" aria-expanded="false"
                        aria-controls="collapsenine"
                        class="btn btn-link collapsed text-dark font-wnine-bold text-uppercase collapsible-link">  Who is a buyer in Connekkt Media</button>
                    </h2>
                  </div>
                  <div id="collapsenine" aria-labelledby="headingnine" data-parent="#accordionExample" class="collapse">
                    <div class="card_2 p-3">
                      <p class="font-weight-light m-0">
                        A buyer in Connekkt Media is typically an individual or organization seeking to purchase products, services, or content offered by sellers on the platform. Buyers may include:<br>

        •        Brands looking for content solutions <br>
        •        Consumers interested in specific content <br>
        •        Businesses seeking partnerships to enhance their outreach and engagement
                      </p>
                    </div>
                  </div>
                </div><!-- End -->

            </div><!-- End -->
          </div>
        </div>
      </div>

      <br> <br>
  
      <!-- Footer -->
      <div class="footer">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <div class="footer-col first">
                          <h6>About Connekkt Media</h6>
                          <p class="p-small">Blockchain powered Video/Audio Content Rights Marketplace.
                              World’s only Assisted sales platform for Digital Media Content Rights
                          </p>
                      </div> <!-- end of footer-col -->
                      <div class="footer-col second">
                          <h6>Useful Links</h6>
                          <ul class="list-unstyled li-space-lg p-small">
                              <li><a href="terms.html">Terms & Conditions</a><br/> <a href="privacy.html">Privacy
                                      Policy</a><br> <a routerLink="/faqs">FAQs</a> </li>
                              <!-- <li>Useful: <a href="#">Colorpicker</a>, <a href="#">Icon Library</a>, <a
                                      href="#">Illustrations</a></li>
                              <li>Menu: <a class="page-scroll" href="#header">Home</a>, <a class="page-scroll"
                                      href="#intro">Intro</a>, <a class="page-scroll" href="#features">Features</a>, <a
                                      class="page-scroll" href="#details">Details</a></li> -->
                          </ul>
                      </div> <!-- end of footer-col -->
                      <div class="footer-col third">
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-facebook-f fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-twitter fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-pinterest-p fa-stack-1x"></i>
                              </a>
                          </span>
                          <span class="fa-stack">
                              <a href="#your-link">
                                  <i class="fas fa-circle fa-stack-2x"></i>
                                  <i class="fab fa-instagram fa-stack-1x"></i>
                              </a>
                          </span>
                          <p class="p-small">We would love to hear from you <a
                                  href="mailto:support@connekkt.media"><strong>support@connekkt.media</strong></a>
                                  <br/><a
                                      href="tel:+4407466 31893"><strong>+44 07466 331893</strong></a></p>
  
                      </div> <!-- end of footer-col -->
                  </div> <!-- end of col -->
              </div> <!-- end of row -->
  
               <!-- Copyright -->
      <div class="copyright">
          <div class="container">
              <div class="row">
                  <div class="col-lg-12">
                      <p class="p-small">Copyright © <a href="#your-link">Connekkt Media</a></p>
                  </div> <!-- end of col -->
              </div> <!-- enf of row -->
          </div> <!-- end of container -->
      </div> <!-- end of copyright -->
      
          </div> <!-- end of container -->
      </div> <!-- end of footer -->
      <!-- end of footer -->